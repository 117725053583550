.deletion-popup-wrapper {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #ffffff;
  padding: 20px;
  width: 80vw;
  max-width: 500px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 100;
  overflow: hidden;
  padding: 0 30px;
}
.deletion-popup-wrapper p {
  font-weight: 700;
  font-size: 20px;
  padding: 20px 10px;
}
.deletion-popup-wrapper .buttons-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0;
}

.deletion-popup-wrapper .buttons-wrapper button {
  padding: 10px 20px;
  border-radius: 12px;
  background-color: #0d6efd;
  color: #ffffff;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
}
.deletion-popup-wrapper .buttons-wrapper .delete {
  background-color: #ff0000;
}
