.activate-game-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  margin-top: 30px;
}

.activate-game-wrapper .top {
  display: flex;
  width: 100%;
}

.activate-game-wrapper .top .back {
  width: 33%;
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}

.activate-game-wrapper .top .title {
  width: 33%;
  display: flex;
  justify-content: center;
  font-size: 40px;
}

.game-details {
  color: #ffffff;
  display: flex;
  font-size: 25px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* .game-details-title {
  font-size: 30px;
} */

.game-name,
.game-home-team,
.game-away-team {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  /* max-width: 400px;   */
  margin-inline-start: 74%;
}

.game-name span,
.game-home-team span,
.game-away-team span {
  margin-left: 5px; /* Add a small space between label and value */
  font-weight: bold; /* Make the values bold to distinguish them */
}

.game-duration {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}
.game-duration .game-duration-title {
  font-size: 25px;
}
.game-duration .counter {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  width: 20%;
}

.game-duration .counter .duration {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}
.activate-btn {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.activate-btn button {
  background-color: #ffffff;
  color: #000000;
  font-weight: 700;
  font-size: 1.3rem;
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;
}
.activate-btn button:hover {
  background-color: blue;
  color: #ffffff;
}
