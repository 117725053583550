.results-popup-wrapper {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #ffffff;
  padding: 20px;
  width: 80vw;
  max-width: 1000px;
  height: 80%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 100;
  overflow: hidden;
  padding: 0 30px;
}
.results-popup-wrapper .close-popup {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 0 0;
}
.results-popup-wrapper .results-images {
  display: flex;
  justify-content: center;
}
.results-popup-wrapper .results-images .image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 50%;
}
.results-popup-wrapper .results-images .image-wrapper.image_1080 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 50%;
}
.results-popup-wrapper .results-images .image-wrapper .size {
  font-weight: 600;
}
.results-popup-wrapper .results-images .image-wrapper img {
  width: 50%;
  height: auto;
  border-radius: 12px;
}
.results-popup-wrapper .results-images .image-wrapper img.image {
  width: 70%;
}
