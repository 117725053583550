.create-quiz-wrapper .game_form .input-wrapper select {
  padding: 5px 15px;
  width: 50%;
  height: 50px;
  border-radius: 12px;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
  background-color: white;
}
.create-quiz-wrapper .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.create-quiz-wrapper .title div {
  width: 33%;
  text-align: center;
}
.create-quiz-wrapper .title .back {
  font-size: 1.1rem;
  cursor: pointer;
}
.error {
  color: red;
  width: 100%;
  font-size: 20px;
  text-align: center;
  margin: 20px 0 10px 0;
}

.btn.create-quiz {
  background-color: #ffffff;
  width: 50%;
  color: #000000;
  font-weight: 700;
  font-size: 1.3rem;
  border-radius: 12px;
  margin: 50px 0;
}

.btn.create-quiz:hover {
  background-color: blue;
  color: #ffffff;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 30px 0;
}
.create-quiz-wrapper .game_form .input-wrapper input,
.create-quiz-wrapper .game_form .input-wrapper label {
  width: 50%;
  max-width: 600px;
}
.create-quiz-wrapper .game_form .input-wrapper label {
  margin: 30px auto 5px auto;
  padding-inline-start: 0;
}
