.dashboard {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard .sideNav {
  width: 250px;
  padding: 30px;
}

.dashboard .main-content {
  width: 100%;
  padding: 20px;
  color: #ffffff;
}
