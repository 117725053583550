.register-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}
.register-wrapper h1 {
  font-size: 2.5rem;
  color: #ffffff;
  font-weight: 700;
  margin-top: 50px;
}
.register-wrapper .register-form {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.register-wrapper .register-form .error {
  margin: 0;
}
.register-wrapper .register-form .btn.create-game {
  width: 100%;
  margin: 50px 0 30px 0;
}
/* .register-wrapper .register-form .login-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}
.register-wrapper .register-form .login-link:hover {
  color: blue;
} */
