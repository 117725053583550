.create-team-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  width: 100%;
  padding-top: 20px;
  background-color: #000; /* Set the background color to black */
}

.title {
  margin-bottom: 30px;
  font-size: 2.5rem;
  color: #ffffff;
  font-weight: 700;
}

.team-form {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.input-wrapper label {
  font-size: 1.3rem;
  width: 50%;
  color: #ffffff;
  margin: 10px 0 5px 0;
  align-self: flex-start;
}
.input-wrapper input {
  padding: 5px 5px 0 15px;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
}
/* Toggle Wrapper */
.toggle-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
}

.toggle-status {
  font-size: 1.2rem;
  color: #ffffff;
}

/* Toggle Switch Container */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  background-color: #767676;
  border-radius: 30px;
  transition: background-color 0.4s ease;
  cursor: pointer;
}

/* Hidden checkbox input */
.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The toggle slider (background) */
.toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 30px;
  transition: background-color 0.4s ease;
}

/* The white circle inside the toggle switch */
.toggle-slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  top: -5px;
  bottom: 2px;
  background-color: #ffffff;
  transition: transform 0.4s ease;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(109, 60, 60, 0.3);
}

/* Change background color and move circle when toggled */
.toggle-input:checked + .toggle-slider {
  background-color: #4caf50;
}

.toggle-input:checked + .toggle-slider:before {
  transform: translateX(30px); /* Move the circle to the right when active */
}

/* Submit button styles */
button.create-team {
  background-color: #ffffff;
  width: 100%;
  height: 50px;
  color: #000000;
  font-weight: 700;
  font-size: 1.3rem;
  border-radius: 12px;
  margin-top: 30px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

button.create-team:hover {
  background-color: blue;
  color: #ffffff;
}

button.create-team:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 255, 0.5);
}

button.create-team:active {
  transform: scale(0.98);
}
