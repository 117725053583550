.statistics-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}
.statistics-wrapper .title-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  color: #ffffff;
}
.statistics-wrapper .title-wrapper .title {
  margin: 0;
}
.main-stats {
  width: 60vw;
  margin: 0 auto; /* Center the table horizontally */
}

.main-stats table {
  width: 100%;
  max-width: 600px;
  border: 1px solid #ddd;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
  color: #ffffff;
  margin: 50px auto;
}

.main-stats th,
.main-stats td {
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-stats th {
  width: 50%;
  background-color: black;
  border-bottom: 2px solid #aaa;
  font-size: 1.2rem;
  color: white;
  text-align: left; /* Left-align the header text */
  transition: background-color 0.3s ease;
}

.main-stats td {
  width: 50%; /* Values take the remaining 40% of the table's width */
  text-align: center; /* Center-align the values */
  transition: background-color 0.3s ease;
}

.main-stats tbody tr,
.main-stats th {
  background-color: black;
  transition: background-color 0.3s ease;
}

.main-stats tbody tr:hover,
.main-stats th.hovered {
  background-color: #007bff;
}
