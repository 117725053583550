.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.header div {
}
.header .title {
  font-size: 3rem;
  color: #ffffff;
  font-weight: 700;
  padding-top: 30px;
  margin-bottom: 0;
  width: 50%;
}
.header .logo-wrapper {
  padding: 20px 0 0 50px;
  width: 25%;
}
.header .logo-wrapper img {
  width: 50px;
}
.header .avatar {
  padding: 20px 50px 0 0;
  width: 25%;
  display: flex;
  justify-content: flex-end;
}

.header .avatar img {
  width: 50px;
  border-radius: 50%;
}
