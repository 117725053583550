/* Bold */
@font-face {
  font-family: "DAZN Oscine";
  src: url("../../../public/fonts/DAZN_Oscine_Bd.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Light Italic */
@font-face {
  font-family: "DAZN Oscine";
  src: url("../../../public/fonts/DAZN_Oscine_LtIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* Bold Italic */
@font-face {
  font-family: "DAZN Oscine";
  src: url("../../../public/fonts/DAZN_Oscine_BdIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* Light */
@font-face {
  font-family: "DAZN Oscine";
  src: url("../../../public/fonts/DAZN_Oscine_Lt.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Italic */
@font-face {
  font-family: "DAZN Oscine";
  src: url("../../../public/fonts/DAZN_Oscine_It.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Regular */
@font-face {
  font-family: "DAZN Oscine";
  src: url("../../../public/fonts/DAZN_Oscine_Rg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Extra Bold */
@font-face {
  font-family: "DAZN Oscine";
  src: url("../../../public/fonts/DAZN_Oscine_XBd.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* Extra Bold Italic */
@font-face {
  font-family: "DAZN Oscine";
  src: url("../../../public/fonts/DAZN_Oscine_XBdIt.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
