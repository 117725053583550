/* teams.css */

.teams-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.teams-table {
  width: 100%;
  margin-top: 20px;
  /* border: 1px solid #ffffff; */
}

.table-header,
.table-row {
  display: flex;
  width: 100%;
}

.header-cell,
.body-cell {
  flex: 1;
  padding: 10px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  border-bottom: 1px solid #ffffff;
}

.body-cell:hover {
  color: black;
}
svg:hover {
  color: black;
}

.table-header {
  background-color: black;
  color: white;
  font-size: 1.2rem;
}

.table-body .table-row {
  background-color: black;
  color: white;
  transition: background-color 0.3s ease;
}

.table-body .table-row:hover {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.edit-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-cell svg {
  font-size: 1.5rem;
  cursor: pointer;
}

.table-body .table-row:hover svg {
  color: white;
}

.table-body .table-row svg:hover {
  color: #ddd;
}

.empty-message {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #888;
  width: 100%;
}
