.create-game-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  width: 100%;
  padding-top: 20px;
}
.create-game-wrapper .title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
}

.create-game-wrapper .game_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.create-game-wrapper .game_form .input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-game-wrapper .game_form .input-wrapper .error {
  margin-top: 5px;
  color: red;
  width: 60%;
}
.create-game-wrapper .game_form .input-wrapper label {
  font-size: 1.3rem;
  width: 50%;
  color: #ffffff;
  margin: 30px 0 5px 0;
  /* align-self: auto; */
  padding-inline-start: 7%;
  width: 100%;
}

/* specific game name label */
.create-game-wrapper .game_form .input-wrapper .game_name_label {
  padding-inline-start: 7%;
}

.create-game-wrapper .game_form .input-wrapper input {
  padding: 5px 5px 0 15px;
  width: 87%;
  height: 50px;
  border-radius: 12px;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
}
.create-game-wrapper .game_form .input-wrapper input.date-input {
  padding: 5px 50px 5px 15px;
}
.btn.create-game {
  background-color: #ffffff;
  width: 87%;
  color: #000000;
  font-weight: 700;
  font-size: 1.3rem;
  border-radius: 12px;
  margin: 50px 0;
}
.btn.create-game:hover {
  background-color: blue;
  color: #ffffff;
}
.side-by-side {
  display: flex;
  justify-content: center;

  width: 100%;
}

.side-by-side .input-wrapper {
  width: 48%;
}
.create-game-wrapper .game_form .side-by-side .input-wrapper input {
  width: 100%;
}
