.resetPassword-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
}

.resetPassword-wrapper .input-wrapper {
  width: 80%;
  max-width: 600px;
}

.resetPassword-wrapper .input-wrapper input,
.resetPassword-wrapper .input-wrapper button,
.resetPassword-wrapper .input-wrapper label {
  width: 80%;
}

.resetPassword-wrapper .input-wrapper label {
  align-self: auto;
}
.resetPassword-wrapper .input-wrapper button {
  margin: 20px 0 0 0;
}
