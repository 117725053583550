.login-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}
.login-wrapper .login-container {
  width: 50%;
  max-width: 500px;
  padding-top: 30px;
  margin-top: 50px;
  -webkit-box-shadow: -1px 1px 15px -3px rgba(255, 255, 255, 0.86);
  box-shadow: -1px 1px 15px -3px rgba(255, 255, 255, 0.86);
  transition: box-shadow 0.5s ease-in-out; /* Add transition for smooth effect */
}
.login-wrapper .login-container .title {
  margin-bottom: 0;
}
.login-wrapper .login-container:hover {
  cursor: pointer;
  -webkit-box-shadow: -1px 1px 35px -3px rgba(255, 255, 255, 1);
  box-shadow: -1px 1px 35px -3px rgba(255, 255, 255, 1); /* Increase the shadow on hover */
}
.login-wrapper .login-container .input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-wrapper .login-container .input-wrapper input,
.login-wrapper .login-container .input-wrapper button {
  width: 80%;
  margin-bottom: 0;
}

.login-wrapper .login-container .input-wrapper label {
  width: 80%;
  margin: 10px auto 5px auto;
}

.forgot-password {
  color: #ffffff;
  text-align: center;
  margin-bottom: 40px;
  text-decoration: underline;
  cursor: pointer;
}
.forgot-password:hover {
  color: #007bff;
}
