.App {
  min-height: 100vh;
  background-color: black;
  font-family: "DAZN Oscine", sans-serif;
  font-weight: bold;
}
.popup-open {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  filter: brightness(25%);
  background-color: #000000;
  pointer-events: none;
}
