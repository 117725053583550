table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
}

th,
td {
  padding: 10px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th {
  background-color: black;
  border-bottom: 2px solid #aaa;
  font-size: 1.2rem;
}

/* Center align cells with icons */
/* td.create_quiz,
th.create_quiz,
th.game_link,
td.game_link,
td.create_game,
th.create_game,
td.edit_quiz,
th.edit_quiz,
td.edit_game,
th.edit_game,
td.delete_game,
th.delete_game,
td.activate_game,
th.activate_game,
th.start_game {
   text-align: center;
} */

thead th,
td {
  text-align: center;
}

td.link-wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}
td.link-wrapper :hover {
  color: #000000;
}
tbody tr {
  background-color: black;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #ffffff;
}

tbody tr:hover {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

tbody tr svg:hover {
  fill: black;
}
td.game_link {
  display: flex;
  justify-content: center;
  gap: 10px;
}
td span.game_identifier:hover {
  text-decoration: underline;
  color: #000000;
}
td svg {
  font-size: 1.5rem;
  cursor: pointer;
  vertical-align: middle;
}

/* Additional style for tab container */
.react-tabs__tab-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.react-tabs__tab--selected {
  background-color: #f0f0f0;
  border-color: #aaa #aaa #fff;
}
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 40px 0;
}
.search .by-name input {
  height: 38px;
  background-color: #000000;
  color: #ffffff;
  padding-inline-start: 10px;
  font-weight: 600;
  border: 1px solid #ffffff;
  border-radius: 4px;
  width: 300px;
}
.search .by-name input:focus {
  outline: none;
  border: 1px solid #007bff;
}
.search .by-name input::placeholder {
  color: #ffffff;
}
