.nav_title {
  color: #ffffff;
  padding-left: 35px;
  font-size: 1.5rem;
  padding-top: 50px;

  margin-bottom: 10px;
}
.sideNav .nav_list {
  cursor: pointer;
}

.sideNav .nav_list .nav-link.text-white:hover {
  color: blue !important;
  cursor: pointer;
}

.sideNav .nav_list li.nav_list {
  margin-bottom: 10px;
}

.sideNav .nav_list .nav-link.text-white.active {
  background-color: rgb(14, 112, 224);
}
.sideNav .nav_list .nav-link.text-white.active:hover {
  color: #ffffff !important;
}
